import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "body" }, [_c("portal", { attrs: { "to": "sider-items" } }, [_c("div", { staticClass: "kh-application p-4" }, [_c("p", { staticClass: "m-0 text-uppercase" }, [_vm._v("\u81EA\u52D5\u751F\u6210-\u652F\u6255\u660E\u7D30\u66F8\u4F5C\u6210")]), _c("h5", { staticClass: "mb-0 text-capitalize" }, [_vm._v("Jido Seisei")])]), _c("a-menu", { attrs: { "mode": "inline", "theme": "light", "inline-collapsed": false, "default-selected-keys": _vm.selectedKeys } }, [_vm._l(_vm.dataMenu, function(item) {
    return [item.child.length === 0 ? [_c("a-menu-item", { key: item.key, on: { "click": function($event) {
      return _vm.clickItem($event);
    } } }, [_c("router-link", { attrs: { "to": item.path } }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1)], 1)] : _vm._e()];
  })], 2)], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var JidoSeisei_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".sidebar-container .ant-menu[data-v-10e27846],.sidebar-container .ant-menu-submenu[data-v-10e27846]>.ant-menu{border:none}.sidebar-container .ant-menu-inline[data-v-10e27846]{max-height:80vh;overflow-x:hidden;overflow-y:overlay;scrollbar-width:thin;scrollbar-color:#babac0}.sidebar-container.ant-layout-sider-collapsed .sidebar-header[data-v-10e27846]{justify-content:center}.sidebar-container.ant-layout-sider-collapsed .sidebar-header .logo-container[data-v-10e27846],.sidebar-container.ant-layout-sider-collapsed .kh-application *[data-v-10e27846]{width:0}.sidebar-container .sidebar-header[data-v-10e27846]{height:56px;box-shadow:inset 0 -1px #eaedf0,inset -1px 0 #eaedf0;background-color:#fff;justify-content:space-around;background:#f9fafb}.sidebar-container .sidebar-header .logo-container[data-v-10e27846]{width:auto;overflow:hidden;transition:width .2s}.sidebar-container p[data-v-10e27846]{font-weight:700;font-size:14px;color:#99c0ff;text-transform:uppercase;white-space:nowrap}.sidebar-container h5[data-v-10e27846]{font-size:1.25rem;font-weight:600;color:#fff;text-transform:capitalize;white-space:nowrap}.trigger[data-v-10e27846]{font-size:18px;cursor:pointer;transition:color .3s}.trigger[data-v-10e27846]:hover{color:#1890ff}.logo[data-v-10e27846]{height:32px;background:rgba(255,255,255,.2)}.kh-application[data-v-10e27846]{height:97px;background-position:center}.kh-application *[data-v-10e27846]{width:100%;overflow:hidden;transition:width .2s}\n")();
const __vue2_script = {
  name: "JidoSeisei",
  data() {
    return {
      dataMenu: [
        {
          title: "Back to Main Menu",
          path: "/",
          key: "home",
          icon: "appstore",
          child: []
        },
        {
          title: "Jido Seisei",
          path: "/jido-seisei",
          key: "jido-seisei",
          icon: "file-text",
          child: []
        }
      ],
      selectedKeys: [],
      windowWidth: window.innerWidth,
      collapsedWidth: 1024
    };
  },
  metaInfo: {
    title: "Jido Seisei"
  },
  watch: {
    $route() {
      this.updateMenu();
    },
    windowWidth(newVal) {
      if (newVal <= this.collapsedWidth) {
        this.$emit("collapsedMounted", true);
      }
    }
  },
  async created() {
    this.updateMenu();
    if (this.windowWidth <= this.collapsedWidth) {
      this.$emit("collapsedMounted", true);
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    updateMenu: function() {
      if (!this.$route.matched[0])
        return;
      let path = this.$route.matched[0].path;
      let title = path.substring(path.lastIndexOf("/") + 1);
      this.selectedKeys = [title];
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    clickItem: function(e) {
      this.selectedKeys = [e.key];
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "10e27846", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var JidoSeisei = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { JidoSeisei as default };
